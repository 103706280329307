<template>
  <div>
    <!-- <template v-if="countRP > 0">
      <div class="box-alert-home" :class="showNotifRefund ? 'show' : ''"
        v-if="role === 'General Affair' || role === 'Finance'">
        <div class="text-center">
          <b-alert variant="danger" show dismissible>
            Terdapat <b>{{ countRP }}</b> Refund dana pending!
            <button @click="handleCheckRefund" class="btn-link">Cek Sekarang</button>
          </b-alert>
        </div>
      </div>
    </template> -->
    <CountFpp />
  </div>
</template>


<script>
import CountFpp from '../components/dashboard/CountFpp.vue';
export default {
  name: 'dashboard',
  components: {
    CountFpp
  },
  computed: {
    countRP() {
      return this.$store.state.refund.refund_pending_count
    }
  },
  data() {
    return {
      id: null,
      isSubmitting: false,
      initStatus: 0,
      isLoading: false,
      token: localStorage.getItem("token"),
      role: localStorage.getItem("role"),
      showNotifRefund: false
    }
  },
  methods: {
    init() {
      if (localStorage.getItem("reload") !== null && localStorage.getItem("reload") === "1") {
        localStorage.removeItem("reload")
        window.location.reload(true)
      }
      this.initStatus = 1
    },
    async handleCheckRefund() {
      await this.$router.push('refund-pending')
    },
    fnNotifRefund() {
      setTimeout(() => {
        this.showNotifRefund = true
      }, 2000);
    }
  },

  created() {
    this.fnNotifRefund()
    this.$store.dispatch("getCountRefundPending")
    this.init()
  }
}
</script>
