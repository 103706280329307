<template>
    <div class="row">
        <div class="col-sm-6 col-lg-4">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title d-flex justify-content-between align-items-start">
                        <span>IM Perbaikan</span>
                        <button @click="this.getCountMemo" class="btn btn-sm btn-info text-white">
                            <i class="fa fa-refresh"></i>
                        </button>
                    </h5>
                </div>
                <div class="card-body"
                    v-if="role === 'General Affair' || role === 'GM General Affair' || role === 'GM Support'">
                    <div class="d-flex text-warning align-items-center justify-content-between mb-2">
                        <span>Belum Disetujui</span>
                        <span class="mr-2 font-bold">
                            <b v-if="!isLoading">{{ countFpp.belum_disetujui_gm_pic || "-" }}</b>
                            <i v-if="isLoading" class="fa fa-spin fa-spinner"></i>
                        </span>
                    </div>
                    <div class="d-flex text-success align-items-center justify-content-between mb-2">
                        <span>Sudah Disetujui</span>
                        <span class="mr-2 font-bold">
                            <b v-if="!isLoading">{{ countFpp.disetujui_gm_pic || "-" }}</b>
                            <i v-if="isLoading" class="fa fa-spin fa-spinner"></i>
                        </span>
                    </div>
                    <div class="d-flex text-primary mt-4 align-items-center justify-content-between mb-2">
                        <span>
                            <b>Total</b>
                        </span>
                        <span class="mr-2 font-bold">
                            <b v-if="!isLoading">{{ countFpp.total_memo_gm_pic || "-" }}</b>
                            <i v-if="isLoading" class="fa fa-spin fa-spinner"></i>
                        </span>
                    </div>
                </div>
                <div class="card-body" v-if="role === 'Maintenance'">
                    <div class="d-flex text-warning align-items-center justify-content-between mb-2">
                        <span>Diproses</span>
                        <span class="mr-2 font-bold">
                            <b v-if="!isLoading">{{ countFpp.diproses || "-" }}</b>
                            <i v-if="isLoading" class="fa fa-spin fa-spinner"></i>
                        </span>
                    </div>
                    <div class="d-flex text-success align-items-center justify-content-between mb-2">
                        <span>Sudah Disetujui</span>
                        <span class="mr-2 font-bold">
                            <b v-if="!isLoading">{{ countFpp.diselesaikan || "-" }}</b>
                            <i v-if="isLoading" class="fa fa-spin fa-spinner"></i>
                        </span>
                    </div>
                    <div class="d-flex text-primary mt-4 align-items-center justify-content-between mb-2">
                        <span>
                            <b>Total</b>
                        </span>
                        <span class="mr-2 font-bold">
                            <b v-if="!isLoading">{{ countFpp.total_memo || "-" }}</b>
                            <i v-if="isLoading" class="fa fa-spin fa-spinner"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "CountFpp",
    computed: {
        ...mapState("loading", ["isLoading"]),
    },
    data: () => {
        return {
            countFpp: {},
            userId: localStorage.getItem("id"),
            role: localStorage.getItem("role"),
        };
    },
    methods: {
        async getCountMemo() {
            const response = await this.$store.dispatch("getCountMemo", {
                role: this.$store.state.role || this.role
            })
            this.countFpp = await response.data
        }
    },
    created() {
        this.getCountMemo();
    }
}
</script>